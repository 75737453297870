import * as React from 'react';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface ModelProps {
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  repeate: {
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
  };
}

const CalendarPageTimeSpan: FC<ModelProps> = (props) => {
  const { t } = useTranslation();
  const repetable = Object.values(props.repeate).some((value) => value);
  const weekdays: any[] = [];

  for (const [key, value] of Object.entries(props.repeate)) {
    if (value) {
      weekdays.push(key);
    }
  }

  const clock = (dateTimeString: string) => {
    const date = new Date(dateTimeString);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const day = (dateTimeString: string) => {
    const date = new Date(dateTimeString);
    const day = date.getDate();
    const monthNumber = date.getMonth();
    const year = date.getFullYear();
    const monthArray = [
      'january',
      'february',
      'march',
      'april',
      'may',
      'june',
      'july',
      'august',
      'september',
      'october',
      'november',
      'december',
    ];

    return `${day}. ${t(
      `calendar/timeanddate/${monthArray[monthNumber]}`
    )} ${year}`;
  };

  const listweekdays = (days: any) => {
    const translated: any[] = [];
    if (days && days.length > 0) {
      days.forEach((day: string) => {
        translated.push(t(`calendar/timeanddate/${day}`));
      });
    }

    if (translated.length === 0) {
      return '';
    }
    if (translated.length === 1) {
      return `${translated[0]} `;
    }
    if (translated.length === 2) {
      return `${translated[0]} ${t(`calendar/timeanddate/and`)} ${
        translated[1]
      }`;
    }
    const lastElement = translated.pop();
    return `${translated.join(', ')} ${t(
      `calendar/timeanddate/and`
    )} ${lastElement} `;
  };

  return (
    <>
      {repetable && (
        <p className=" bg-white-warm text-sm py-2 px-4 my-4 rounded">
          {weekdays.length > 0 && (
            <>
              {t('calendar/timeanddate/every')} &#32;
              {listweekdays(weekdays)}
            </>
          )}
          {props.startTime && (
            <>
              {t('calendar/timeanddate/from')} &#32;
              {clock(props.startTime)} &#32;
            </>
          )}
          {props.startTime && props.endTime && (
            <>{t('calendar/timeanddate/to')} &#32;</>
          )}
          {props.endTime && <>{clock(props.endTime)} &#32;</>}
          {props.startDate && (
            <>
              {t('calendar/timeanddate/from')} {day(props.startDate)} &#32;
            </>
          )}
          {props.startDate && props.endDate && (
            <>{t('calendar/timeanddate/to')} &#32;</>
          )}
          {props.endDate && <>{day(props.endDate)} &#32;</>}
        </p>
      )}

      {!repetable && (
        <p className="mb-10 text-xl desktop:text-2xl font-light">
          {props.startDate && <>{day(props.startDate)} &#32;</>}

          {props.startDate && props.endDate && <>&#32;&rarr;&#32;</>}

          {props.endDate && <>{day(props.endDate)}, &#32;</>}

          {props.startTime && <>{clock(props.startTime)} &#32;</>}

          {props.startTime && props.endTime && <>&#32;&rarr;&#32;</>}

          {props.endTime && <>{clock(props.endTime)} &#32;</>}
        </p>
      )}
    </>
  );
};

export default CalendarPageTimeSpan;
